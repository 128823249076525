import { useUserService } from "../user/useUserService";
import { useMissionService } from "../mission/useMissionService";
import { useDeviceService } from "../device/useDeviceService";
import { deviceGeneralTelemetries } from "../middlewares/deviceGeneralTelemetries";
import { useCollectorService } from "../collector/useCollectorService";

export default function useServices() {
  const deviceService = useDeviceService([deviceGeneralTelemetries]);
  const missionService = useMissionService();
  const userService = useUserService();
  useCollectorService({ deviceService, missionService, userService });
}
