import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './DeviceMap.sass';
import MapboxReact, { Marker } from 'react-map-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from "mapbox-gl";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { setContext } from "src/redux/ducks/appContextSlice";
import { useRef } from 'react';
import { useState } from 'react';
import { CgShapeRhombus } from 'react-icons/cg';
import DestinationPointIcon from 'src/assets/img/destination_point.svg';
import MapPersonMarker, { PersonMarkerTypes } from './MapPersonMarker/MapPersonMarker';
import MapDockMarker from './MapDockMarker/MapDockMarker';
import MapDroneMarker from './MapDroneMarker/MapDroneMarker';
import { AppThemeNames, useAppTheme } from 'src/helper/AppThemeProvider';
import { addOrUpdateMarkers, getShipsListQuery, removeMarkers, removeShipsListQueryStatus, setActivePathId, setLastClickCoordinates, setLastSelectedMarker, setMarkers } from './DeviceMapSlice';
import MapUnitMarker from './MapUnitMarker/MapUnitMarker';
import { buildEdgeBounds, convertToLngLat, findDistinctItems, isValidCoordinate } from 'src/helper/utils';
import { FaLaptopHouse } from 'react-icons/fa';
import classNames from 'classnames';
import Button from 'src/hci/common/Button/Button';
import { ContextMenu, useContextMenu } from 'src/hci/common/ContextMenu/ContextMenu';
import { MAPBOX_ACCESS_TOKEN } from 'src/helper/constants';
import useResizeObserver from '@react-hook/resize-observer';
import DeviceMapToolbar from './DeviceMapToolbar/DeviceMapToolbar';
import MapShipMarker from './MapShipMarker/MapShipMarker';
import { TbShip } from 'react-icons/tb';
import { IoEarth } from 'react-icons/io5';

export const DeviceMapMarkerTypes = {
  DRONE: 'drone',
  DOCK: 'dock',
  MOBILE_DEVICE: 'mobile_device',
  SHIP: 'ship',
  OPERATION_UNIT: 'operation_unit',
  LOCATION: 'location'
}

export const DeviceMapPointerModes = {
  SELECT_POINT: 'select_point',
  NORMAL: 'noraml'
}

export const DeviceMapLayerStyles = {
  STREET: 1,
  SATELLITE: 2,
  STREET_DARK: 3,
};

export const DeviceMapLocationTypes = {
  MISSION_DESTINATION: 'destination',
}

export const MapboxStyles = {
  [DeviceMapLayerStyles.STREET]: 'mapbox://styles/mapbox/streets-v12',
  [DeviceMapLayerStyles.SATELLITE]: 'mapbox://styles/mapbox/satellite-streets-v12',
  [DeviceMapLayerStyles.STREET_DARK]: 'mapbox://styles/mapbox/dark-v10',
}

export const DeviceMapPathType = {
  DEVICE_FOOTMARK: 'device_footmark',
  ACTIVE_MISSION_ROUTE: 'active_mission_route',
}

export const DEVICE_MAP_SELECTION_MARKER_ID = 'selection_marker';

function DeviceMap({ className, resizeTrigger, markers: externalMarkers = [], initialFly = true, readGlobalMarkers = true, hideControls = false, ...props}) {
  const defaultZoom = 8;
  const defaultCoordinates = { long: 103.8365792, lat: 1.3555013 };
  const contextMenuId = 'map_context_menu';

  const mapInstance = useRef();
  const isMapFlying = useRef();
  const lastFlyCoords = useRef();
  const isMapZooming = useRef();
  const mapContainerRef = useRef();
  const fitToBoundsTimeoutId = useRef();
  const edgeSenseTimeoutId = useRef();
  const geocoderInstance = useRef();
  const navigationControlInstance = useRef();

  const [initialFitToBoundDone, setInitialFitToBoundDone] = useState(false);
  const [currentZoom, setCurrentZoom] = useState(defaultZoom);
  const [currentCenter, setCurrentCenter] = useState(defaultCoordinates);
  const [edgeSenseEnabled, setEdgeSenseEnabled] = useState(true);
  const [lastGpsLocation, setLastGpsLocation] = useState();
  const [enlargedMap, setEnlargedMap] = useState(false);
  const [narrwoMap, setNarrowMap] = useState();

  const dispatch = useDispatch();
  const store = useStore();
  const { show: showContextMenu } = useContextMenu({ id: contextMenuId });
  const pointerMode = useSelector(state => state.deviceMap.pointerMode);
  const [localMarkers, setLocalMarkers] = useState(externalMarkers);
  const globalMarkers = useSelector(state => state.deviceMap.markers);
  const mapRenderId = useSelector(state => state.deviceMap.mapRenderId);
  const lastSelectedMarker = useSelector(state => state.deviceMap.lastSelectedMarker);
  const mapPathList = useSelector(state => state.deviceMap.pathList);
  const activeMapPathId = useSelector(state => state.deviceMap.activePathId);
  const newFlyCoordinates = useSelector(state => state.deviceMap.newFlyCoordinates);
  const shipsListQueryStatus = useSelector(state => state.deviceMap.shipsListQueryStatus);

  const selectedMarkerCoords = useMemo(() => {
    const marker = localMarkers.find(item => item.data?.sourceId === lastSelectedMarker?.data?.sourceId);
    
    if(marker) return { lat: marker.lat, lng: marker.long };
    else return null;
  }, [localMarkers, lastSelectedMarker]);

  const flightRouteGeoJson = useRef({
    'type': 'FeatureCollection',
    'features': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'LineString',
          'coordinates': []
        }
      }
    ]
  });
  
  const waypointRouteGeoJson = useRef({
    'type': 'FeatureCollection',
    'features': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'LineString',
          'coordinates': []
        }
      }
    ]
  });

  const handleContextMenu = (event) => {
    showContextMenu({
      event: event.originalEvent,
      props: {
          mapEvent: event
      }
    })
  }

  const handleQueryShipsClick = ({ id, event, props }) => {
    const lngLat = props?.mapEvent?.lngLat;
    if(!lngLat) return;

    dispatch(getShipsListQuery({
      lat: lngLat.lat,
      lon: lngLat.lng,
      radius: 3,
    }));
  }

  const handleRemoveShipsDataClick = () => {
    dispatch(removeShipsListQueryStatus());
  }

  const setSelectedLocationOnMap = (lat, long) => {
    if(!isValidCoordinate({lat, long})) return;

    dispatch(setLastClickCoordinates({
      lat: lat.toFixed(7),
      lng: long.toFixed(7),
    }));

    if (store.getState().deviceMap.pointerMode !== DeviceMapPointerModes.SELECT_POINT) return;

    dispatch(addOrUpdateMarkers({
      type: DeviceMapMarkerTypes.LOCATION,
      title: 'Destination Point',
      lat: lat.toFixed(7),
      long: long.toFixed(7),
      data: {
        id: DEVICE_MAP_SELECTION_MARKER_ID
      }
    }));
  };

  const handleOnMapClick = (e) => {
    setSelectedLocationOnMap(e.lngLat.lat, e.lngLat.lng);
  }

  const handleMapApiLoaded = (map) => {
    if (!map) return;

    // Handle drag events of map for edge-sense
    map.on('dragstart', () => {
      clearTimeout(edgeSenseTimeoutId.current);
      setEdgeSenseEnabled(false);
    });

    // Add flight route line to the map
    map.addSource('flight-route-line', {
      'type': 'geojson',
      'data': flightRouteGeoJson.current
    });

    map.addLayer({
      'id': 'flight-route',
      'type': 'line',
      'source': 'flight-route-line',
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#4769b6',
        'line-width': 5,
        'line-opacity': 0.8
      }
    });

    // Add waypoint route line to the map
    map.addSource('waypoint-route-line', {
      'type': 'geojson',
      'data': waypointRouteGeoJson.current
    });

    map.addLayer({
      'id': 'waypoint-route',
      'type': 'line',
      'source': 'waypoint-route-line',
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#e62929',
        'line-width': 5,
        'line-opacity': 0.8,
        'line-dasharray': [0.2, 2]
      }
    });

    handleContainerChange();
  }

  const handleOnMarkerClick = useCallback((marker) => {
    if (marker?.type === DeviceMapMarkerTypes.LOCATION) return;
    if (pointerMode === !DeviceMapPointerModes.SELECT_POINT) return;

    dispatch(setLastSelectedMarker(marker));

    if (marker?.type === DeviceMapMarkerTypes.DOCK || marker?.type === DeviceMapMarkerTypes.DRONE) {
      if (marker.data?.sourceId)
        dispatch(setActivePathId(marker.data.sourceId));
    }
  }, [pointerMode]);

  const applyDeviceMapPathList = (mapPathList) => {
    const map = mapInstance.current;
    if (!mapPathList || mapPathList.length === 0 || !map) return;
    
    const footmarkPathGeoJson = flightRouteGeoJson.current;
    const missionPathGeoJson = waypointRouteGeoJson.current;

    mapPathList.forEach(pathItem => {
      const { type, coords } = pathItem;

      const pathGeoJson = type === DeviceMapPathType.DEVICE_FOOTMARK ? footmarkPathGeoJson : missionPathGeoJson;
      pathGeoJson.features[0].geometry.coordinates = coords;

      if(type === DeviceMapPathType.DEVICE_FOOTMARK)
        map.getSource('flight-route-line').setData(pathGeoJson);
      else if(type === DeviceMapPathType.ACTIVE_MISSION_ROUTE)
        map.getSource('waypoint-route-line').setData(pathGeoJson);
    })
  }

  const updateCurrentCenter = (coord) => {
    setCurrentCenter(coord);
  }

  const handleContainerChange = () => {
    const map = mapInstance.current;
    if (enlargedMap && !narrwoMap) {
      enableGeocoder();
    }
    else {
      disableGeocoder();
    }

    setTimeout(() => {
      if (map && currentCenter)
        flyToCoords(currentCenter.lng, currentCenter.lat, enlargedMap ? 17 : 18);
    }, 1000);

    setEdgeSenseEnabled(true);
  }

  const recenterMap = useCallback(() => {
    if (!lastGpsLocation) return;
    let newCenter = { ...lastGpsLocation };

    if (
      currentCenter &&
      currentCenter.lat === lastGpsLocation.lat &&
      currentCenter.lng === lastGpsLocation.lng
    ) {
      newCenter.lat = Number(newCenter.lat) + 0.00001;
      newCenter.lng = Number(newCenter.lng) + 0.00001;
    }

    updateCurrentCenter(newCenter);
    setEdgeSenseEnabled(true);
  }, [lastGpsLocation, currentCenter]);

  const zoomToDevice = useCallback(() => {
    if(lastGpsLocation)
      flyToCoords(selectedMarkerCoords.lng, selectedMarkerCoords.lat, 18);
  }, [selectedMarkerCoords, lastGpsLocation]);

  const zoomToAll = () => {
    fitToMapMarkers(false);
  }

  const flyToCoords = (lng, lat, zoom) => {
    const map = mapInstance.current;
    if (!map || !lng || !lat) return;

    if (isMapFlying.current) {
      lastFlyCoords.current = { lng, lat };
      return;
    }

    map.once('moveend', () => {
      isMapFlying.current = false;

      if (lastFlyCoords.current) {
        flyToCoords(lastFlyCoords.current.lng, lastFlyCoords.current.lat);
        lastFlyCoords.current = undefined;
      }
    });

    isMapFlying.current = true;

    map.flyTo({
      center: [Number(lng).toFixed(6), Number(lat).toFixed(6)],
      zoom: zoom || currentZoom || defaultZoom,
    });
  };

  const fitToBounds = (bounds, useEdges = true, ignoreDistantPoints = false) => {
    let normalizedBounds = normalizeCoords(bounds);
    
    if(ignoreDistantPoints) {
      const distinctItems = findDistinctItems(normalizedBounds, 50);
      normalizedBounds = normalizedBounds.filter(item => !distinctItems.includes(item));
    }

    if(useEdges) {
      normalizedBounds = buildEdgeBounds(normalizedBounds);
    }
    
    try {
      console.log('normalized bounds', normalizedBounds);
      mapInstance.current.fitBounds(normalizedBounds, {
        maxZoom: 16,
        ...(useEdges && { padding: 150 }),
      });
    } catch (error) {
      console.error('Error fitting bounds', error, bounds);
    }
  };

  const fitToMapMarkers = useCallback((ignoreDistantPoints = false) => {
    const bounds = localMarkers.filter(item => item.type !== DeviceMapMarkerTypes.LOCATION).map((item) => ([item.long, item.lat]));
    fitToBounds(bounds, true, ignoreDistantPoints);
  }, [localMarkers]);

  const normalizeCoords = coordList => coordList.filter(item => {
    const [long, lat] = item;
    if(!long || !lat) return false;
    console.log(long, lat)
    return (lat > -90 && lat < 90) && (long > -180 && long < 180);
  }).map((item) => ([
    parseFloat(item[0]).toFixed(6), parseFloat(item[1]).toFixed(6)
  ]));

  const enableGeocoder = () => {
    if (!mapInstance.current || geocoderInstance.current) return;

    const geocoder = geocoderInstance.current = new MapboxGeocoder({
      accessToken: MAPBOX_ACCESS_TOKEN,
      marker: null,
      mapboxgl,
    });

    // log coordinates of geocoder when user selects a result
    geocoder.on('result', (info) => {
      const centerCoordinate = info.result?.center;

      if (Array.isArray(centerCoordinate)) {
        setSelectedLocationOnMap(centerCoordinate[1], centerCoordinate[0]);

        // setEdgeSenseEnabled(false);
        clearTimeout(edgeSenseTimeoutId.current);
      }
    });

    const navigation = navigationControlInstance.current = new mapboxgl.NavigationControl();

    if(!hideControls) {
      mapInstance.current.addControl(geocoder, 'top-right');
      mapInstance.current.addControl(navigation, 'bottom-right');
    }
  }

  const disableGeocoder = () => {
    if (!mapInstance.current || !geocoderInstance.current) return;
    mapInstance.current.removeControl(geocoderInstance.current);
    mapInstance.current.removeControl(navigationControlInstance.current);
    geocoderInstance.current = null;
  }

  const checkPointerEdges = (coord) => {
    const map = mapInstance.current;
    const container = mapContainerRef.current;

    if (!map || !coord || !container) return;

    const pointerPoint = map.project(coord);
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;
    const edgeThreshold = Math.min(containerWidth / 5, containerHeight / 5);

    const shouldRecenter = (
      (containerWidth - pointerPoint.x < edgeThreshold) ||
      (containerHeight - pointerPoint.y < edgeThreshold) ||
      (pointerPoint.x < edgeThreshold) ||
      (pointerPoint.y < edgeThreshold)
    );

    if (shouldRecenter) updateCurrentCenter(coord);
  }

  useResizeObserver(mapContainerRef, (entry) => {
    setEnlargedMap(entry.contentRect.height > 400);
    setNarrowMap(entry.contentRect.width < 350);
    mapInstance.current?.resize();
    recenterMap();
  });

  useEffect(() => {
    setLastGpsLocation(selectedMarkerCoords);
    if (!currentCenter) updateCurrentCenter(selectedMarkerCoords);

    if (selectedMarkerCoords && edgeSenseEnabled) {
      checkPointerEdges(selectedMarkerCoords);
    }
  }, [selectedMarkerCoords, edgeSenseEnabled, currentCenter]);

  useEffect(handleContainerChange, [enlargedMap, narrwoMap]);

  useEffect(() => {
    const coord = convertToLngLat(newFlyCoordinates);
    if(!coord) return

    flyToCoords(coord[0], coord[1], 17);
  }, [newFlyCoordinates]);

  useEffect(() => {
    if (mapInstance.current) {
      mapInstance.current.resize();
    }
  }, [resizeTrigger, mapRenderId]);

  useEffect(() => {
    let targetPathList = [
      { type: DeviceMapPathType.DEVICE_FOOTMARK, coords: [] },
      { type: DeviceMapPathType.ACTIVE_MISSION_ROUTE, coords: [] }
    ];

    if (activeMapPathId) {
      const activePath = mapPathList.filter(item => item.deviceId === activeMapPathId);
      if (activePath?.length) {
        targetPathList = activePath;
      }
    }

    applyDeviceMapPathList(targetPathList);
  }, [activeMapPathId, mapPathList]);

  useEffect(() => {
    if (!readGlobalMarkers) {
      setLocalMarkers(externalMarkers);
    }
  }, [externalMarkers, readGlobalMarkers]);

  useEffect(() => {
    if (readGlobalMarkers) {
      setLocalMarkers(globalMarkers);
    }
  }, [globalMarkers, readGlobalMarkers]);

  useEffect(() => {
    if(false && mapInstance.current && globalMarkers?.length > 0 && !initialFitToBoundDone) {
      fitToMapMarkers(true);

      clearTimeout(fitToBoundsTimeoutId.current);
      fitToBoundsTimeoutId.current = setTimeout(() => {
        setInitialFitToBoundDone(true);
      }, 200);
    }
  }, [localMarkers, initialFitToBoundDone]);

  useEffect(() => {
    if (!currentCenter || isMapZooming.current) return;
    flyToCoords(currentCenter.lng, currentCenter.lat);
  }, [currentCenter]);

  useEffect(() => {
    const { data, status } = shipsListQueryStatus || {};
    console.log('🚢 Ships list query status:', shipsListQueryStatus);

    if(!status || status !== 'success') {
      dispatch(removeMarkers({ type: DeviceMapMarkerTypes.SHIP }));
      return;
    }

    const vessels = data.data?.vessels || [];
    const totalResults = data.data?.total || 0;
    const prevMarkers = store.getState().deviceMap.markers;

    const newMarkers = vessels.map((vessel) => {
      return {
        type: DeviceMapMarkerTypes.SHIP,
        title: vessel.name,
        lat: vessel.lat,
        long: vessel.lon,
        data: vessel,
      };
    });

    dispatch(setMarkers([
      ...prevMarkers.filter((item) => item.type !== DeviceMapMarkerTypes.SHIP),
      ...newMarkers,
    ]));
  }, [shipsListQueryStatus]);

  useEffect(() => {
    return () => clearInterval(edgeSenseTimeoutId.current);
  }, []);

  const createMarkerElements = useCallback((markersData, selectedMarker) => {
    return markersData?.map((marker, index) => {
      const { type, title, lat, long, data } = marker;
      const key = `${type}-${index}`;
      
      let markerContent = null;
      let markerClassName = pointerMode === !DeviceMapPointerModes.SELECT_POINT ? 'clickable-marker' : '';
      let markerZIndex = 0;

      if(selectedMarker && selectedMarker.data?.sourceId === data?.sourceId)
        markerClassName += ' selected';

      switch (type) {
        case DeviceMapMarkerTypes.MOBILE_DEVICE:
          markerContent = <MapPersonMarker title={title} type={PersonMarkerTypes[data?.type?.toUpperCase()]} className={markerClassName} />;
          break;

        case DeviceMapMarkerTypes.DOCK:
          markerContent = <MapDockMarker title={title} isOnline={data.isOnline} className={markerClassName} />;
          break;

        case DeviceMapMarkerTypes.SHIP:
          markerContent = <MapShipMarker title={title} data={data} className={markerClassName} />;
          break;

        case DeviceMapMarkerTypes.OPERATION_UNIT:
          markerContent = <MapUnitMarker title={title} className={markerClassName} />;
          break;

        case DeviceMapMarkerTypes.DRONE:
          markerContent = <MapDroneMarker title={title} heading={data.heading} className={markerClassName} />;
          markerZIndex = 1;
          break;

        case DeviceMapMarkerTypes.LOCATION:
          markerContent = (
            <img
              src={DestinationPointIcon}
              alt={title || 'Place Marker'}
              title={title}
              className="destination-point"
            />
          );
          markerZIndex = 2;
          break;
      }

      return (
        <Marker 
          key={key} 
          latitude={lat} 
          longitude={long} 
          onClick={() => handleOnMarkerClick(marker)}
          style={{
            zIndex: markerZIndex,
          }}
        >
          {markerContent}
        </Marker>
      );
    });
  }, [pointerMode]);

  return (
    <div className={classNames("device-map", className)} ref={mapContainerRef}>
      <MapboxReact
        onLoad={event => {
          mapInstance.current = event.target;
          handleMapApiLoaded(event.target);
        }}
        cursor={pointerMode === DeviceMapPointerModes.SELECT_POINT ? 'crosshair' : 'grab'}
        onZoomStart={() => {
          isMapZooming.current = true;
        }}
        onZoomEnd={(e) => {
          const newState = e.viewState;
          isMapZooming.current = false;

          if (!isMapFlying.current && newState.zoom)
            setCurrentZoom(newState.zoom);
        }}
        onMoveEnd={(e) => {
          const newState = e.viewState;
          setCurrentZoom(newState.zoom);
        }}
        onClick={handleOnMapClick}
        onContextMenu={handleContextMenu}
        mapStyle={MapboxStyles[DeviceMapLayerStyles.SATELLITE]}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        initialViewState={{
          longitude: defaultCoordinates?.long,
          latitude: defaultCoordinates?.lat,
          zoom: defaultZoom,
        }}
      >
        {createMarkerElements(localMarkers, lastSelectedMarker)}
      </MapboxReact>
      <DeviceMapToolbar 
        extended={enlargedMap}
        onRecenterClick={recenterMap} 
        onDeviceZoomClick={zoomToDevice}
        onZoomOutClick={zoomToAll}
      />
      <ContextMenu id={contextMenuId}>
        <ContextMenu.Submenu label={
          <>
            <TbShip className='icon' />
            Ships AIS data
          </>
        }>
          <ContextMenu.Item id="query_ships" onClick={handleQueryShipsClick}>Query ships in area</ContextMenu.Item>
          <ContextMenu.Item id="remove_ships_data" onClick={handleRemoveShipsDataClick}>Remove ships data</ContextMenu.Item>
        </ContextMenu.Submenu>
        <ContextMenu.Separator />
        <ContextMenu.Item id="zoom_out" onClick={() => fitToMapMarkers(false)}>
          <IoEarth className='icon' />
          Show all entities
        </ContextMenu.Item>
      </ContextMenu>
    </div>
  )
}

export default DeviceMap