import { createSlice, current } from "@reduxjs/toolkit";
import { DeviceMapMarkerTypes } from "./DeviceMap";
import { PersonMarkerTypes } from "./MapPersonMarker/MapPersonMarker";
import { generateId } from "src/helper/utils";
import { apiCallBegan } from "src/redux/action/api";

const defaultCoordinates = {long: 103.837738, lat: 1.356382};

const DeviceMapSlice = createSlice({
  name: "deviceMap",
  initialState: {
    lastClickCoordinates: null,
    lastSelectedMarker: null,
    mapRenderId: null,
    markers: [],
    pointerMode: null,
    pathList: [],
    activePathId: null,
    newFlyCoordinates: null,
    shipsListQueryStatus: null,
  },
  reducers: {
    setLastClickCoordinates(state, action) {
      const data = action.payload;
      return { ...state, lastClickCoordinates: data };
    },
    setLastSelectedMarker(state, action) {
      const data = action.payload;
      return { ...state, lastSelectedMarker: data };
    },
    setMapRenderId(state, action) {
      const data = action.payload;
      return { ...state, mapRenderId: data };
    },
    setMarkers(state, action) {
      const data = action.payload;
      return { ...state, markers: data };
    },
    addOrUpdateMarkers(state, action) {
      let data = action.payload;

      if(!Array.isArray(data)) data = [data];
      if(data.length === 0) return state;

      data.forEach((item) => {
        const markerId = state.markers.find(
          (marker) => marker.type === item.type && (
            // item.data props should be equal to marker.data props
            Object.keys(item.data)?.every(key => item.data[key] === marker.data[key])
          )
        )?.id;

        if(markerId) {
          state.markers = state.markers.map((marker) => {
            if(marker.id === markerId) {
              return { ...marker, ...item };
            }

            return marker;
          });
        } else {
          state.markers.push({ ...item, id: item.id || generateId() });
        }
      });

      return state;
    },
    removeMarkers(state, action) {
      let targetData = action.payload;

      if (!Array.isArray(targetData)) targetData = [targetData];
      const markersToRemove = [];

      targetData.forEach(item => {
        const {type, data} = item;

        console.log({type, data})

        const targetIds = state.markers.filter(
          (marker) => marker.type === type && (
            !data ||
            Object.keys(data).every(key => data[key] === marker.data[key])
          )
        ).map(marker => marker.id);

        markersToRemove.push(...targetIds);
      });

      if (markersToRemove.length > 0) {
        state.markers = state.markers.filter((marker) => !markersToRemove.includes(marker.id));
      }

      return state;
    },
    resetMarkers(state, action) {
      return { ...state, markers: [] };
    },
    setPointerMode(state, action) {
      const data = action.payload;
      return { ...state, pointerMode: data };
    },
    addOrUpdatePath(state, action) {
      let data = action.payload;

      if(!Array.isArray(data)) data = [data];
      if(data.length === 0) return state;

      data.forEach((item) => {
        const {deviceId, type} = item;
        const index = state.pathList.findIndex(
          (path) => path.deviceId === deviceId && path.type === type
        );

        if(index !== -1) {
          state.pathList[index] = item;
        } else {
          state.pathList.push(item);
        }
      });

      return state;
    },
    removePath(state, action) {
      let data = action.payload;

      if(!Array.isArray(data)) data = [data];
      if(data.length === 0) return state;

      data.forEach((item) => {
        const {deviceId, type} = item;
        const index = state.pathList.findIndex(
          (path) => path.deviceId === deviceId && path.type === type
        );

        if(index !== -1) {
          state.pathList.splice(index, 1);
        }
      });

      return state;
    },
    addToPathCoordinates(state, action) {
      const data = action.payload;
      const {deviceId, type, coords} = data;

      const index = state.pathList.findIndex(
        (path) => path.deviceId === deviceId && path.type === type
      );

      if(index !== -1) {
        const currCoords = state.pathList[index].coords;
        const lastCoord = currCoords[currCoords.length - 1];

        if(
          Math.abs(lastCoord[0] - coords[0]?.[0]) < 0.0001 && 
          Math.abs(lastCoord[1] - coords[0]?.[1]) < 0.0001
        ) return state;

        state.pathList[index].coords = [
          ...state.pathList[index].coords,
          ...coords
        ];
      }
      else {
        state.pathList.push({
          deviceId,
          type,
          coords: coords
        });
      }

      return state;
    },
    setActivePathId(state, action) {
      const data = action.payload;
      return { ...state, activePathId: data };
    },
    setNewFlyCoordinates(state, action) {
      const data = action.payload;
      return { ...state, newFlyCoordinates: data };
    },
    setShipsListQueryStatus(state, action) {
      const data = action.payload;
      return { ...state, shipsListQueryStatus: data };
    },
    removeShipsListQueryStatus(state, action) {
      return { ...state, shipsListQueryStatus: null };
    },
  },
});

export const {
  setLastClickCoordinates,
  setLastSelectedMarker,
  setMapRenderId,
  setMarkers,
  addOrUpdateMarkers,
  resetMarkers,
  setPointerMode,
  removeMarkers,
  addOrUpdatePath,
  removePath,
  addToPathCoordinates,
  setActivePathId,
  setNewFlyCoordinates,
  setShipsListQueryStatus,
  removeShipsListQueryStatus,
} = DeviceMapSlice.actions;

export default DeviceMapSlice.reducer;

export const getShipsListQuery = (data, meta) => {
  const API_KEY = '9c421bb7-4de1-4c66-a18a-fa99578f9349';
  const {lat, lon, radius = 50} = data;

  return apiCallBegan({
    url: `https://api.datalastic.com/api/v0/vessel_inradius?api-key=${API_KEY}&lat=${lat}&lon=${lon}&radius=${radius}`,
    method: "Get",
    onSuccess: setShipsListQueryStatus.type,
    onError: setShipsListQueryStatus.type,
    meta,
  })};