import classNames from 'classnames';
import { Button as RadixButton } from '@radix-ui/themes'
import './Button.sass';
import { ButtonProps } from '@radix-ui/themes/dist/cjs/components/button';

function Button({ prefix, postfix, className, children, ...props}: ButtonProps & { prefix?: React.ReactNode, postfix?: React.ReactNode }) {
  return (
    <RadixButton className={classNames('ir-button', className)} {...props}>
      { children }
    </RadixButton>
  )
}

export default Button