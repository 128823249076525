import { list, getList } from "src/components/Dashboard/ApplicationsArea/DeviceApp/DockManagerApp/DockManagerAppSlice";
import { useFrontendHub } from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "./AccountStateProvider";
import { HubEventName, HubName } from "./HubConnection";
import { useRef } from "react";
import rfdc from "rfdc";

export const DeviceOnlineStatus = {
  ONLINE: "on",
  OFFLINE: "off",
  IDLE: "idle"
};

function useDockList(consumerId) {
  const dispatch = useDispatch();
  const clone = rfdc();
  const { account } = useAccount();
  const serverMessageHandlerIds = useRef([]);
  const systemMessageHandlerIds = useRef([]);
  const api = useSelector((state) => state.api);
  const docks = useSelector((state) => state.dockManager);
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [devicesFetched, setDevicesFetched] = useState(false);
  const initiated = useRef(false);
  const {
    hub: frontendConnectionManager,
    connected: frontendHubConnected,
    checkDockConnectivity,
  } = useFrontendHub();

  const changeOnlineStatus = (id, status) => {
    const items = clone(devices);

    setDevices(items.map((item) => {
      if (item.id === id) {
        item.onlineStatus = status;
      }

      return item;
    }));
  };

  useEffect(() => {
    if (api.token !== undefined && !initiated.current) {
      dispatch(getList());
      initiated.current = true;
    }
    return () => {
      dispatch(list({ status: "" }));
    };
  }, [api.token]);

  useEffect(() => {
    if (docks.list.data !== undefined && frontendHubConnected) {
      setDevices(docks.list.data.docks);
      setDevicesFetched(true);
      setLoading(false);
    }
  }, [docks.list, frontendHubConnected])

  useEffect(() => {
    if (!devicesFetched) return;
    const items = clone(devices);

    setDevices(items.map((item) => {
      if (frontendHubConnected) {
        item.onlineStatus = DeviceOnlineStatus.IDLE;
        checkDockConnectivity(account.user.localAccountId, item.id);
      } else {
        item.onlineStatus = DeviceOnlineStatus.OFFLINE;
      }

      return item;
    }));
  }, [devicesFetched, frontendHubConnected]);

  const serverMessageHandlers = useMemo(() => devices.map((item) => [
    {
      identity: item.id,
      name: HubEventName.CONNECTED,
      platform: HubName.DOCK,
      handler: (data) => {
        changeOnlineStatus(data?.userId, DeviceOnlineStatus.IDLE);
        const dock = devices.find((d) => d.id === data?.userId);
        if (dock) {
          checkDockConnectivity(account.user.localAccountId, data?.userId);
        }
      },
    },
    {
      identity: item.id,
      name: HubEventName.CONNECTIVITY_STATUS,
      platform: HubName.DOCK,
      handler: (data) => {
        console.log('✨ Dock connected', data?.userId, devices)
        changeOnlineStatus(data?.userId, DeviceOnlineStatus.ONLINE);
      },
    },
    {
      identity: item.id,
      name: HubEventName.DISCONNECTED,
      platform: HubName.DOCK,
      handler: (data) => {
        changeOnlineStatus(data?.userId, DeviceOnlineStatus.IDLE);
      },
    },
  ]), [devices]);

  const systemMessageHandlers = useMemo(() => [
    {
      identity: account?.user?.localAccountId,
      name: HubEventName.DISCONNECTED,
      handler: (data) => {
        setDevices(items => items.map((item) => {
          item.onlineStatus = DeviceOnlineStatus.OFFLINE;
          return item;
        }));
      },
    },
    {
      identity: account?.user?.localAccountId,
      name: HubEventName.CONNECTED,
      handler: (data) => {
        devices?.forEach((item) => {
          checkDockConnectivity(account.user.localAccountId, item.id);
        });
      },
    },
  ], [devices]);

  useEffect(() => {
    if(!frontendConnectionManager)
      return;
    
    frontendConnectionManager.unsubscribeSystemMessages(
      systemMessageHandlerIds.current
    );

    systemMessageHandlerIds.current =
      frontendConnectionManager?.subscribeSystemMessages(
        systemMessageHandlers,
        consumerId
      );

    frontendConnectionManager.unsubscribeServerMessages(
      serverMessageHandlerIds.current
    );

    serverMessageHandlerIds.current =
      frontendConnectionManager.subscribeServerMessages(
        serverMessageHandlers.reduce(function (result, current) {
          return [...result, ...current];
        }, []),
        consumerId
      );

    return () => {
      frontendConnectionManager.unsubscribeServerMessages(
        serverMessageHandlerIds.current
      );

      frontendConnectionManager?.unsubscribeSystemMessages(
        systemMessageHandlerIds.current
      );
    };
  }, [serverMessageHandlers, systemMessageHandlers]);

  return {
    loading,
    docks: devices,
  };
}

export default useDockList