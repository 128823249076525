import axios from "axios";
import { setApiToken } from "src/components/ApiProvider/ApiProviderSlice";
import { loading } from "src/ui/Loading/LoadingSlice";
import * as actions from "../action/api";

var baseURL = "";
var token = "";
const api =
  ({ dispatch }) =>
    (next) =>
      async (action) => {
        if (action.type === "settings/apiPrefix") baseURL = action.payload;
        if (action.type === setApiToken.type) token = action.payload.token;
        if (action.type !== actions.apiCallBegan.type) return next(action);
        next(action);
        const { url, method, data, onSuccess, onError, onSpin, baseURI, meta } =
          action.payload;
        try {
          dispatch(
            onSpin
              ? {
                type: onSpin,
                payload: { status: "pending" },
              }
              : loading({ status: true, name: onSuccess })
          );
          const response = await axios.request({
            baseURL: baseURI !== undefined ? baseURI : baseURL,
            url,
            method,
            headers: {
              Authorization: token,
            },
            data,
          });
          if (onSuccess) {
            dispatch({
              type: onSuccess,
              payload: { status: "success", data: response.data, meta },
            });
            dispatch(loading({ status: false, name: onSuccess }));
          }
          //dispatch(actions.apiCallSuccess(response.data));
        } catch (error) {
          dispatch(loading({ status: false, name: onError }));
          if (error.response === undefined) {
            const payload = {
              name: onError,
              status: "failed",
              message: "your server connection lost",
            };
            dispatch(actions.apiCallError(payload));
            return;
          }
          var options = {};
          if (!onSpin) {
            if (error.response.status === 400) {
              options = {
                status: "validation",
                errors: error.response.data.errors,
              };
              if (onError) {
                dispatch({
                  name: onError,
                  type: onError,
                  payload: options,
                });
              }
            } else if (error.response.status === 401) {
              const payload = {
                name: onError,
                status: "failed",
                message: error.response.data.message,
              };
              dispatch({
                type: onError,
                payload,
              });
              dispatch(actions.apiCallError(payload));
            } else if (error.response.status === 403) {
              console.log("403", error.response.data);
              const payload = {
                name: onError,
                status: "forbidden",
                message: error.response.data.error,
              };
              dispatch({
                type: onError,
                payload,
              });
              dispatch(actions.apiCallError(payload));
            } else if (error.response.status === 404) {
              const payload = {
                name: onError,
                status: "notFounded",
                message:
                  "Your requested resource not founded or has been expired!",
              };
              dispatch(actions.apiCallError(payload));
              dispatch({
                type: onError,
                payload,
              });
            }
          } else {
            if (error.response.status === 400) {
              options = {
                status: "validation",
                errors: error.response.data.errors,
              };
            } else if (error.response.status === 401) {
              options = { status: "failed", message: error.response.data.error };
            } else if (error.response.status === 403) {
              options = { status: "forbidden", message: error.response.data };
            }
            dispatch({
              type: onSpin,
              payload: options,
            });
          }
        }

        // dispatch(actions.apiCallFailed({ payload: options }));
      };
export default api;
