export const MissionType = {
  WAYLINE: 'wayline',
  DFR: 'dfr',
};

export const MissionStatus = {
  IDLE: 'idle',
  PLANNED: 'planned',
  READY: 'ready',
  PREPARING: 'preparing',
  EXECUTING: 'executing',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  CANCELED: 'canceled',
  FAILED: 'failed',
  COMPLETED: 'completed',
  RETURNING: 'returning',
};

export const NotActiveMissionStatusSet = [
  MissionStatus.IDLE, 
  MissionStatus.FAILED, 
  MissionStatus.COMPLETED,
  MissionStatus.CANCELED,
  MissionStatus.STOPPED,
];

export const onboardWaypointAction = {
  UPLOAD: 1,
  START: 2,
  PAUSE: 3,
  RESUME: 4,
  STOP: 5,
  SET_INFO: 6,
  GET_INFO: 7,
  MISSION_INIT: 20,
  MISSION_TERMINATE: 21,
  MISSION_QUERY: 22,
};

export const WaypointStateV3 = {
  IDLE: 0, 
  PREPARE: 16, 
  TRANS_MISSION: 32,
  MISSION: 48,
  BREAK: 64,
  RESUME: 80,
  RETURN_FIRSTPOINT: 98
}

export const WaypointStateTextV3 = {
  [WaypointStateV3.IDLE]: 'Idle',
  [WaypointStateV3.PREPARE]: 'Drone is checking the mission...',
  [WaypointStateV3.TRANS_MISSION]: 'Ascending to optimal altitude, please wait.',
  [WaypointStateV3.MISSION]: 'Mission is now running...',
  [WaypointStateV3.BREAK]: 'Mission paused...',
  [WaypointStateV3.RESUME]: 'Mission resumed...',
  [WaypointStateV3.RETURN_FIRSTPOINT]: 'Returning to the first point...',
}

export const WaypointStateV2 = {
  GROUND_STATION_NOT_START: 0,
  MISSION_PREPARED: 1,
  ENTER_MISSION: 2,
  EXECUTE_FLYING_ROUTE_MISSION: 3,
  PAUSE_STATE: 4,
  ENTER_MISSION_AFTER_ENDING_PAUSE: 5,
  EXIT_MISSION: 6,
};

export const WaypointStateTextV2 = {
  [WaypointStateV2.GROUND_STATION_NOT_START]: 'Ground station not start',
  [WaypointStateV2.MISSION_PREPARED]: 'Mission uploaded',
  [WaypointStateV2.ENTER_MISSION]: 'Mission started',
  [WaypointStateV2.EXECUTE_FLYING_ROUTE_MISSION]: 'Mission flying',
  [WaypointStateV2.PAUSE_STATE]: 'Mission paused',
  [WaypointStateV2.ENTER_MISSION_AFTER_ENDING_PAUSE]: 'Mission resumed',
  [WaypointStateV2.EXIT_MISSION]: 'Mission exit happend',
};

export const OnboardWaypointEvent = {
  MISSION_INTERRUPT_EVENT: 1,
  MISSION_RESUME_EVENT: 2,
  MISSION_STOP_EVENT: 3,
  MISSION_ARRIVAL_EVENT: 16,
  MISSION_FINISHED_EVENT: 17,
  MISSION_OBSTACLE_EVENT: 18,
  MISSION_SWITCH_EVENT: 48,
};

export const OnboardWaypointEventCollection = {
  MISSION_INTERRUPT_EVENT: {
    id: 1,
    message: 'Mission inteterrupt',
  },
  MISSION_RESUME_EVENT: {
    id: 2,
    message: 'Mission resumed',
  },
  MISSION_STOP_EVENT: {
    id: 3,
    message: 'Mission stopped',
  },
  MISSION_ARRIVAL_EVENT: {
    id: 16,
    message: 'Mission arrived at point',
  },
  MISSION_FINISHED_EVENT: {
    id: 17,
    message: 'Mission finished',
  },
  MISSION_OBSTACLE_EVENT: {
    id: 18,
    message: 'Mission obstacle detected',
  },
  MISSION_SWITCH_EVENT: {
    id: 48,
    message: 'Mission switch happened',
  },
};

export const WaypointStateCollectionV2 = {
  GROUND_STATION_NOT_START: {
    id: 0,
    message: 'Ground station not start',
  },
  MISSION_PREPARED: {
    id: 1,
    message: 'Mission uploaded',
  },
  ENTER_MISSION: {
    id: 2,
    message: 'Mission started',
  },
  EXECUTE_FLYING_ROUTE_MISSION: {
    id: 3,
    message: 'Mission flying',
  },
  PAUSE_STATE: {
    id: 4,
    message: 'Mission paused',
  },
  ENTER_MISSION_AFTER_ENDING_PAUSE: {
    id: 5,
    message: 'Mission resumed',
  },
  EXIT_MISSION: {
    id: 6,
    message: 'Mission exit happend',
  },
};

export const WaypointStateCollectionV3 = {
  GROUND_STATION_NOT_START: {
    id: 0,
    message: 'Ground station not start',
  },
  MISSION_PREPARED: {
    id: 16,
    message: 'Mission uploaded',
  },
  ENTER_MISSION: {
    id: 32,
    message: 'Mission started',
  },
  EXECUTE_FLYING_ROUTE_MISSION: {
    id: 48,
    message: 'Mission flying',
  },
  PAUSE_STATE: {
    id: 64,
    message: 'Mission paused',
  },
  ENTER_MISSION_AFTER_ENDING_PAUSE: {
    id: 80,
    message: 'Mission resumed',
  },
  EXIT_MISSION: {
    id: 98,
    message: 'Mission exit happend',
  },
};

export const MissionPendingRequestType = {
  REGISTER_ON_SERVER: 'register_on_server',
  QUERY_STATE: 'query_state',
  ONBOARD_UPLOAD: 'onboard_upload',
  ONBOARD_START: 'onboard_start',
  ONBOARD_PREPARATION: 'onboard_preparation',
  ONBOARD_ENTER_MISSION: 'onboard_enter_mission',
  ONBOARD_ENTER_OPERATION: 'onboard_enter_operation',
  ONBOARD_RESUME_MISSION: 'onboard_resume_mission',
  CLOUD_TAKEOFF_PREPARATION: 'cloud_takeoff_preparation',
  CLOUD_FLY_PREPARATION: 'cloud_fly_preparation',
  CLOUD_FLY_TO_POINT: 'cloud_fly_to_point',
  CLOUD_TAKEOFF_TO_POINT: 'cloud_takeoff_to_point',
  PAUSE: 'pause',
  RESUME: 'resume',
  STOP: 'stop',
  TERMINATE: 'terminate',
};

export const MissionPendingRequestStatus = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
};

export const WAYPOINT_MAX_ALTITUDE = 100;
export const WAYPOINT_MIN_ALTITUDE = 5;
export const WAYPOINT_MAX_SPEED = 10;
export const WAYPOINT_MIN_RTH_ALTITUDE = 20;