import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "src/redux/action/api";
import { MeasurementSystem } from "./constants";
const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    apiPrefix: "",
    countries: [],
    videoStreamRegions: [],
    measurementSystem: MeasurementSystem.METRIC
  },
  reducers: {
    apiPrefix(state, action) {
      const data = action.payload;
      return { ...state, apiPrefix: data };
    },
    countries(state, action) {
      const data = action.payload;
      return { ...state, countries: data };
    },
    videoStreamRegions(state, action) {
      const data = action.payload;
      return { ...state, videoStreamRegions: data };
    },
    measurementSystem(state, action) {
      const data = action.payload;
      return { ...state, measurementSystem: data };
    }
  },
});

export const { 
  apiPrefix, 
  countries, 
  videoStreamRegions, 
  measurementSystem 
} = settingsSlice.actions;

export default settingsSlice.reducer;

export const getCountries = () =>
  apiCallBegan({
    url: `/countries`,
    method: "Get",
    onSuccess: countries.type,
    onError: countries.type,
  });

export const getVideoStreamRegions = () =>
  apiCallBegan({
    url: `/regions/videostreaming`,
    method: "Get",
    onSuccess: videoStreamRegions.type,
    onError: videoStreamRegions.type,
  });