import { dockConnectionManager, onboardConnectionManager } from "src/helper/HubConnectionManager";
import { getMissionInfo } from "./missionUtils";
import { getDeviceConnectionGroups, getDeviceInfo } from "src/services/device/common/deviceUtils";
import { onboardWaypointAction } from "./missionConstants";
import { deviceTypes } from "src/helper/constants";
import { djiCloudCustomMethod } from "src/services/common/constants";
import { useFrontendHub } from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import { generateId } from "src/helper/utils";
import { removeMission } from "../MissionServiceSlice";
import useCurrentUserId from "src/helper/useCurrentUserId";

export function useTermianteMission() {
  const { hub: frontendHub, group: frontendGroup } = useFrontendHub();
  const currUserId = useCurrentUserId();

  const terminateMission = (missionId) => {
    const missionDetails = getMissionInfo(item => item.id === missionId);
    const connectionGroups = getDeviceConnectionGroups(missionDetails?.deviceId);
    const deviceInfo = getDeviceInfo(missionDetails?.deviceId);
    
    if(!missionDetails || !connectionGroups || !deviceInfo) {
      console.log('❌ Terminate mission failded: misison, device or connection not found', {missionId, connectionGroups});
      return;
    }

    if(missionDetails.deviceType === deviceTypes.DRONE) {
      onboardConnectionManager?.sendToGroup(deviceInfo?.id, connectionGroups?.send?.commandChannel, {
        waypoint: {
          actionId: onboardWaypointAction.MISSION_TERMINATE,
          missionId: missionDetails.id,
        },
      });
    }

    if(missionDetails.deviceType === deviceTypes.DOCK) {
      dockConnectionManager?.sendToDjiCloudGroup(deviceId, connectionGroups?.send?.commands, {
        method: djiCloudCustomMethod.mission.MISSION_TERMINATE,
        gateway: deviceInfo?.serialNumber,
      });
    }

    frontendHub.sendToGroup(currUserId, frontendGroup?.send?.broadcast, {
      bid: generateId(),
      method: 'mission_finish',
      data: { 
        missionId: missionDetails.id,
        flightId: missionDetails.flightId,
      },
    });

    dispatch(removeMission({ id: missionDetails.id }));
  }

  return terminateMission;
}