import JSZip from "jszip";
import { MAPBOX_ACCESS_TOKEN } from "src/helper/constants";
import { convertToLngLat } from "src/helper/utils";
import { createWaypointTemplate, createWaypointWaylines } from "src/helper/waypointMarkupUtils";
import store from "src/redux/configureStore";

export interface kmzWaypointData {
  latitude: number;
  longitude: number;
  altitude: number;
  speed: number;
  actions: {};
}

export interface kmzInputData {
  droneEnumValue: number;
  droneSubEnumValue: number;
  payloadEnumValue: number;
  payloadSubEnumValue: number;
  speed: number;
  altitude: number;
  waypoints: kmzInputData[];
}

export const createKmz = async (config: kmzInputData) => {

  const kmzConfig = {
    droneEnumValue: config.droneEnumValue,
    droneSubEnumValue: config.droneSubEnumValue ?? 1,
    payloadEnumValue: config.payloadEnumValue ?? 53,
    payloadSubEnumValue: config.payloadSubEnumValue ?? 0,
    pyaloadPositionIndex: 0,
    autoFlightSpeed: Number(config.speed),
    globalHeight: Number(config.altitude),
    waypoints: config.waypoints?.map(item => ({
      lat: item.latitude,
      long: item.longitude,
    }))
  };

  const waypointTemplate = createWaypointTemplate(kmzConfig);
  const waypointWaylines = createWaypointWaylines(kmzConfig);

  const zip = new JSZip();
  const wpmz = zip.folder('wpmz');
  let zipResult;

  wpmz?.file('template.kml', waypointTemplate);
  wpmz?.file('waylines.wpml', waypointWaylines);

  try {
    zipResult = await zip.generateAsync({ type: 'base64' });
    return zipResult;
  } catch (error) {
    console.error('Error creating KMZ file', error);
    return null;
  }
}

export const getMissionInfo = (queryFunction) => {
  const state = store.getState();
  const mission = state.missionService.missions.find(queryFunction);

  return mission;
}

export const getCoordinateGeocoding = (coordinate: any) => {
  const realCoordinate = convertToLngLat(coordinate);
  if(!realCoordinate) return null;

  return new Promise((resolve, reject) => {
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${realCoordinate[0]},${realCoordinate[1]}.json?access_token=${MAPBOX_ACCESS_TOKEN}`)
      .then(response => response.json())
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.error('Error fetching address', error);
        reject(null);
      });
  });
}