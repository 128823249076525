import { Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import AdminHome from "../AdminApplications/AdminHome/AdminHome";
import CustomerManagement from "../AdminApplications/CustomerManagement/CustomerManagement";
import DeviceManagement from "../AdminApplications/DeviceMangement/DeviceMangement";
import GroupManagement from "../AdminApplications/GroupManagement/GroupManagement";
import GuestManagement from "../AdminApplications/GuestManagement/GuestManagement";
import RoleManagement from "../AdminApplications/RoleManagement/RoleManagement";
import StorageManagement from "../AdminApplications/StorageManagement/StorageManagement";
import UserManagement from "../AdminApplications/UserManagement/UserManagement";
import "./AdminContentArea.sass";
import DockManagement from "../AdminApplications/DockManagement/DockMangement";

function AdminContentArea() {
  let { path } = useRouteMatch();
  return (
    <div className="admin-content-area">
      <Switch>
        <Route exact path={`${path}`}>
          <AdminHome />
        </Route>
        <Route exact path={`${path}/home`}>
          <AdminHome />
        </Route>
        <Route exact path={`${path}/users`}>
          <UserManagement />
        </Route>
        <Route exact path={`${path}/roles`}>
          <RoleManagement />
        </Route>
        <Route exact path={`${path}/groups`}>
          <GroupManagement />
        </Route>
        <Route exact path={`${path}/customers`}>
          <CustomerManagement />
        </Route>
        <Route exact path={`${path}/guests`}>
          <GuestManagement />
        </Route>
        <Route exact path={`${path}/devices`}>
          <DeviceManagement />
        </Route>
        <Route exact path={`${path}/docks`}>
          <DockManagement />
        </Route>
        <Route exact path={`${path}/storages`}>
          <StorageManagement />
        </Route>
      </Switch>
    </div>
  );
}

export default AdminContentArea;
