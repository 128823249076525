import { BsCloud, BsCloudRain } from "react-icons/bs";
import { FiBatteryCharging, FiWind } from "react-icons/fi";
import { MdOutlineUploadFile } from "react-icons/md";
import { TbActivity, TbAlertCircle, TbCircleCheck, TbCircleDotted, TbCloudNetwork, TbDeviceAirpodsCase, TbLineHeight, TbTemperature } from "react-icons/tb";
import { MeasurementSystem, MeasurementType } from "src/helper/constants";
import useMeasurement from "src/helper/useMeasurement";
import { DjiDockModeText } from "src/services/device/common/deviceConstants";

export const dockTelemetryItems = (telemetries) => {
  const { convertValue, getMeasurementUnit, measurementSystem } = useMeasurement();

  return [
    {
      name: 'Rain',
      value: {0:"No rain", 1:"Light rain", 2:"Moderate rain", 3:"Heavy rain"}[telemetries?.rainfall ?? '0'],
      icon: telemetries?.rainfall ? <BsCloudRain /> : <BsCloud />
    },
    {
      name: 'Cover',
      value: {0:"Cover Closed", 1:"Cover Open", 2:"Cover Busy", 3:"Cover Abnormal"}[telemetries?.cover_state ?? '0'],
      icon: <TbDeviceAirpodsCase />
    },
    {
      name: 'Wind',
      value: `${Number(convertValue(telemetries?.wind_speed, MeasurementType.SPEED, MeasurementSystem.METRIC)).toFixed(1) ?? '-'} ${getMeasurementUnit(MeasurementType.SPEED)}`,
      icon: <FiWind />
    },
    {
      name: 'Height (ellipsoid)',
      value: `${Number(convertValue(telemetries?.height, MeasurementType.ALTITUDE, MeasurementSystem.METRIC)).toFixed(1) ?? '-'} ${getMeasurementUnit(MeasurementType.ALTITUDE)}`,
      icon: <TbLineHeight />
    },
    {
      name: 'Temperature',
      value: `${Number(convertValue(telemetries?.environment_temperature, MeasurementType.TEMPERATURE, MeasurementSystem.METRIC)) ?? '-'}${getMeasurementUnit(MeasurementType.TEMPERATURE)}`,
      icon: <TbTemperature />
    },
    {
      name: 'Upload Queue',
      value: `${telemetries?.media_file_detail?.remain_upload ?? '-'}`,
      icon: <MdOutlineUploadFile />
    },
    {
      name: 'Drone Battery Charge',
      value: `${telemetries?.drone_charge_state?.capacity_percent ?? '-'} %`,
      icon: <FiBatteryCharging />
    },
    {
      name: 'Network Rate',
      value: `${telemetries?.network_state?.rate?.toFixed(1) ?? '-'} KB/s`,
      icon: <TbCloudNetwork />
    },
    {
      name: 'Drone In Dock',
      value: `${telemetries?.drone_in_dock ? 'Drone In Dock' : 'Drone Not In Dock'}`,
      icon: telemetries?.drone_in_dock ? <TbCircleCheck /> : <TbCircleDotted />
    },
    {
      name: 'Operation Mode',
      value: `${DjiDockModeText[telemetries?.mode_code] ?? '-'}`,
      icon: <TbActivity />
    },
  ];
}