import { MdHeight, MdOutlineSatelliteAlt } from "react-icons/md";
import { TbActivity, TbBattery3 } from "react-icons/tb";
import { MeasurementSystem, MeasurementType } from "src/helper/constants";
import useMeasurement from "src/helper/useMeasurement";
import { DjiDockDroneModeText } from "src/services/device/common/deviceConstants";

export const CloudDroneTelemetryItems = ({telemetries, render}) => {
  const { convertValue, getMeasurementUnit } = useMeasurement();

  const items = [
    {
      title: 'Battery',
      value: `${telemetries?.battery?.capacity_percent ?? '-'}%`,
      icon: <TbBattery3 />
    },
    {
      title: 'Satellites',
      value: `${telemetries?.position_state?.gps_number ?? '-'}`,
      icon: <MdOutlineSatelliteAlt />
    },
    {
      title: 'SPD',
      value: `${convertValue(telemetries?.horizontal_speed, MeasurementType.SPEED, MeasurementSystem.METRIC)} ${getMeasurementUnit(MeasurementType.SPEED)}`,
    },
    {
      title: 'V.S',
      value: `${convertValue(telemetries?.vertical_speed, MeasurementType.SPEED, MeasurementSystem.METRIC)} ${getMeasurementUnit(MeasurementType.SPEED)}`,
    },
    {
      title: 'Elevation (ALT)',
      value: `${Number(convertValue(telemetries?.elevation, MeasurementType.ALTITUDE, MeasurementSystem.METRIC)).toFixed(2) ?? '-'} ${getMeasurementUnit(MeasurementType.ALTITUDE)}`,
      icon: <MdHeight />
    },
    {
      title: 'E.H',
      value: `${Number(convertValue(telemetries?.height, MeasurementType.ALTITUDE, MeasurementSystem.METRIC)).toFixed(2) ?? '-'} ${getMeasurementUnit(MeasurementType.ALTITUDE)}`,
    },
    {
      name: 'Operation Mode',
      value: `${DjiDockDroneModeText[telemetries?.mode_code] ?? '-'}`,
      icon: <TbActivity />
    },
  ];

  return render ? render(items) : items;
};