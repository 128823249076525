import { useEffect, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { dockConnectionManager, frontendConnectionManager, onboardConnectionManager } from "src/helper/HubConnectionManager";
import { addLog } from "src/services/log/LogServiceSlice";
import { createLog } from "src/services/log/common/logUtils";
import { djiCloudCustomMethod, djiCloudMethod } from "../../common/constants";
import { removeMission, removeMissionPendingRequest, setMissionInfo } from "../MissionServiceSlice";
import useOnboardMissionObserver from "./useOnboardMissionObserver";
import useCloudMissionObserver from "./useCloudMissionObserver";
import { getMissionInfo } from "./missionUtils";
import { MissionPendingRequestType, MissionStatus, NotActiveMissionStatusSet, onboardWaypointAction } from "./missionConstants";
import { addOrUpdateMarkers, addOrUpdatePath, removeMarkers } from "src/components/DeviceMap/DeviceMapSlice";
import { DeviceMapLocationTypes, DeviceMapMarkerTypes, DeviceMapPathType } from "src/components/DeviceMap/DeviceMap";
import { getDeviceInfo } from "src/services/device/common/deviceUtils";
import useCurrentUserId from "src/helper/useCurrentUserId";
import { setDeviceInfo } from "src/services/device/DeviceServiceSlice";
import { DeviceOnlineStatus } from "src/helper/useDockList";
import useDialog from "src/helper/useDialog";
import { useTermianteMission } from "./useTerminateMission";
import { useBroadcastToFrontend } from "src/helper/useBroadcastToFrontend";

export default function useMissionObserver() {
  const onboardObserver = useOnboardMissionObserver();
  const cloudObserver = useCloudMissionObserver();
  const currUserId = useCurrentUserId();
  const dialog = useDialog();
  const terminateMission = useTermianteMission();
  const broadcastToFrontend = useBroadcastToFrontend();
  const dockMessageHandlerIds = useRef([]);
  const frontGroupMessageHandlerIds = useRef([]);
  const onboardGroupMessageHandlerIds = useRef([]);
  const pendingRequestsIntervalId = useRef(null);
  const store = useStore();
  const dispatch = useDispatch();
  const deviceList = useSelector((state) => state.deviceService.devices);
  const missions = useSelector((state) => state.missionService.missions);

  // remove missions when a device goes offline
  useEffect(() => {
    const missions = store.getState().missionService.missions;

    deviceList.forEach(device => {
      if(device.onlineStatus !== DeviceOnlineStatus.ONLINE) {
        const mission = missions.find(item => item.deviceId === device.id && !NotActiveMissionStatusSet.includes(item.status));

        if(mission) {
          dispatch(removeMission({ id: mission.id }));
        }
      }
    });

    missions?.filter?.(item => item.deviceId && !deviceList.find(device => device.id === item.deviceId))?.forEach(mission => {
      dispatch(removeMission({ id: mission.id }));
    });
  }, [deviceList]);

  // operations upon mission removal or completion
  useEffect(() => {
    missions.forEach(mission => {
      if(NotActiveMissionStatusSet.includes(mission.status)) {
        dispatch(removeMarkers({
          type: DeviceMapMarkerTypes.LOCATION,
          data: { 
            id: mission.id,
            locationType: DeviceMapLocationTypes.MISSION_DESTINATION
          }
        }));
      } else if ([MissionStatus.PREPARING, MissionStatus.EXECUTING, MissionStatus.PAUSED].includes(mission.status)) {
        const lastWaypoint = mission.waypoints?.[mission.waypoints.length - 1];

        if(lastWaypoint) {
          dispatch(addOrUpdateMarkers({
            type: DeviceMapMarkerTypes.LOCATION,
            title: 'Destination',
            lat: parseFloat(lastWaypoint.latitude).toFixed(7),
            long: parseFloat(lastWaypoint.longitude).toFixed(7),
            data: {
              id: mission.id,
              locationType: DeviceMapLocationTypes.MISSION_DESTINATION
            }
          }));
        }
      }
    });

    const deviceMapMarkers = store.getState().deviceMap.markers;
    deviceMapMarkers?.forEach(marker => {
      if(marker.data?.locationType === DeviceMapLocationTypes.MISSION_DESTINATION && !missions.find(mission => mission.id === marker.data.id)) {
        dispatch(removeMarkers({
          type: DeviceMapMarkerTypes.LOCATION,
          data: marker.data
        }));
      }
    });
  }, [missions]);

  useEffect(() => {
    dockMessageHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: 'missionMessages/*',
        name: ['events', 'services_reply', 'osd', 'commands_reply'],
        handler: (message, meta) => {  
          const missions = store.getState().missionService.missions;
          
          if(message.method === djiCloudMethod.wayline.FLIGHTTASK_READY) {
            const flightIds = message.data?.flight_ids;
  
            Array.isArray(flightIds) && flightIds.forEach(flightId => {
              const mission = missions.find(item => item.flightId === flightId);
              
              if(mission) {
                const missionInfo = {
                  id: mission.id, 
                  isReady: true
                };
    
                dispatch(setMissionInfo(missionInfo));
              }
            });
          }
  
          if(message.method === djiCloudMethod.wayline.RETURN_HOME_INFO) {
            const { flightId, ...info } = message.data;
            const mission = missions.find(item => item.flightId === flightId);

            if(!mission)
              return;
  
            const missionInfo = {
              id: mission.id,
              returnHomeInfo: info,
            };
  
            dispatch(setMissionInfo(missionInfo));
          }

          if(message.method === djiCloudCustomMethod.mission.MISSION_QUERY) {
            const mission = missions.find(item => item.id === message.data?.id);
            const deviceInfo = getDeviceInfo(meta?.fromUserId?.split('_').shift());

            if(!mission && deviceInfo && message.data?.id) {
              const mapCoords = message.data?.waypoints?.map(item => ([ item.longitude, item.latitude ]));
              const remoteMissionData = {
                ...message.data,
                deviceId: message.data?.dockId || message.data?.deviceId,
                status: String(message.data.status).toLowerCase()
              }
              
              dispatch(setMissionInfo(remoteMissionData));

              if(mapCoords.length > 0)
                dispatch(addOrUpdatePath({
                  deviceId: message.data.deviceId,
                  type: DeviceMapPathType.ACTIVE_MISSION_ROUTE,
                  coords: mapCoords
                }));
            }

            if(deviceInfo) {
              dispatch(setDeviceInfo({
                deviceId: deviceInfo.id,
                data: {
                  initialized: Date.now(),
                }
              }));

              console.log('📡 Mission info received from dock', message.data)
            }
            else {
              console.log("❌ [dock] Received mission info doesn't match any mission/device", message.data);
            }
          }
        
          if([
            djiCloudMethod.wayline.FLIGHTTASK_READY,
            djiCloudMethod.wayline.RETURN_HOME_INFO,
          ].includes(message.method)) {
            const logItem = createLog('mission', message.method, message.data);
            dispatch(addLog(logItem))
          }
        },
      },
    ], 'mission-observer');

    frontGroupMessageHandlerIds.current = frontendConnectionManager.subscribeGroupMessages([
      {
        identity: 'frontendBroadcastMessages/*',
        name: ['broadcast'],
        handler: (message) => {
          console.log('📡 [frontendBroadcastMessages]', message);

          if(message.method === 'mission_create' || message.method === 'mission_update') {
            const currMissionInfo = getMissionInfo(item => item.id === message.data.id);
            const newMissionInfo = message.data;

            if(!currMissionInfo || !currMissionInfo.controllerId !== newMissionInfo.controllerId) {
              const mapCoords = newMissionInfo.waypoints?.map(item => ([ item.long || item.longitude, item.lat || item.latitude ]));

              dispatch(setMissionInfo(newMissionInfo));

              if(mapCoords.length > 0)
                dispatch(addOrUpdatePath({
                  deviceId: message.data.deviceId,
                  type: DeviceMapPathType.ACTIVE_MISSION_ROUTE,
                  coords: mapCoords
                }));
            }
          }

          if(message.method === 'mission_finish') {
            dispatch(removeMission({ id: message.data.missionId }));
          }
        },
      },
    ], 'mission-observer');

    onboardGroupMessageHandlerIds.current = onboardConnectionManager.subscribeGroupMessages([
      {
        identity: 'onboardWaypointInfoMessages/*',
        name: ['onDemandChannel'],
        handler: (message, meta) => {
          if(message.actionId === onboardWaypointAction.MISSION_QUERY) {
            const newMissionInfo = message?.mission_info;
            const deviceInfo = getDeviceInfo(meta?.fromUserId?.split('_').shift());
            const mission = getMissionInfo(item => item.id === newMissionInfo?.id);
            
            if(deviceInfo && !mission && newMissionInfo?.id) {
              if(newMissionInfo.status !== MissionStatus.COMPLETED) {
                const mapCoords = newMissionInfo.waypoints?.map(item => ([ item.long || item.longitude, item.lat || item.latitude ]));
                dispatch(setMissionInfo(newMissionInfo));

                if(mapCoords.length > 0)
                  dispatch(addOrUpdatePath({
                    deviceId: newMissionInfo?.deviceId,
                    type: DeviceMapPathType.ACTIVE_MISSION_ROUTE,
                    coords: mapCoords
                  }));
              }
            }
            
            if(deviceInfo) {
              dispatch(setDeviceInfo({
                deviceId: deviceInfo.id,
                data: {
                  initialized: Date.now(),
                }
              }));

              console.log('📡 Mission info received from drone', newMissionInfo);
            }
            else {
              console.log("❌ [onboard] Received mission info doesn't match any mission/device", newMissionInfo)
            }
          }
        },
      },
    ], 'mission-observer');

    pendingRequestsIntervalId.current = setInterval(() => {
      const missions = store.getState().missionService.missions;

      missions.forEach(mission => {
        const pendingRequests = mission.pendingRequests || [];
      
        pendingRequests.forEach(request => {
          let shouldTerminateMission = false;
          let shouldSetMissionToIdle = true;
          let showDialog = true;
          let message = 'Mission request timed out';
          let timeout = 15000;

          switch(request.requestType) {
            case MissionPendingRequestType.REGISTER_ON_SERVER:
              timeout = 30000;
              break;
            case MissionPendingRequestType.CLOUD_TAKEOFF_PREPARATION:
              timeout = 20000;
              break;
            case MissionPendingRequestType.CLOUD_TAKEOFF_TO_POINT:
              timeout = 120000;
              break;
            case MissionPendingRequestType.ONBOARD_ENTER_MISSION:
              timeout = 20000;
              showDialog = false;
              shouldSetMissionToIdle = false;
              break;
            case MissionPendingRequestType.ONBOARD_ENTER_OPERATION:
              timeout = 22000;
              showDialog = true;
              break;
            case MissionPendingRequestType.ONBOARD_RESUME_MISSION:
              timeout = 5000;
              showDialog = false;
              shouldSetMissionToIdle = true;
              message = 'Failed to resume the mission on the device, the waypoint will be canceled.';
              break;
          }

          const elapsed = new Date().getTime() - request.startTimestamp;

          timeout = request.timeout || timeout;
          message = request.failureMessage || message;
          
          shouldTerminateMission = mission?.controllerId === currUserId && shouldTerminateMission;
          message = `${message}. ${shouldTerminateMission ? 'The mission may be teminated now. ' : ''}(${request.requestType})`;

          if(elapsed > timeout) {
            console.log('❌ Mission request timeout', {mission, message, request, pendingRequests});

            if(showDialog && !dialog.isVisible())
              dialog.fire({
                title: 'Mission Error',
                icon: 'error',
                text: message,
                showConfirmButton: true,
                showCloseButton: false,
                confirmButtonText: 'OK',              
              });

            const currMissionInfo = getMissionInfo(item => item.id === mission.id);

            // if(shouldTerminateMission) {
            //   if(![MissionStatus.EXECUTING].includes(currMissionInfo?.status))
            //     terminateMission(mission.id);
            // }

            if(shouldSetMissionToIdle && currMissionInfo?.id) {
              if(![MissionStatus.EXECUTING].includes(currMissionInfo?.status)) {
                const missionChanges = {
                  id: mission.id,
                  status: MissionStatus.IDLE,
                  statusDescription: 'Mission request timed out.',
                };

                dispatch(setMissionInfo(missionChanges));
                broadcastToFrontend('mission_update', {
                  ...currMissionInfo,
                  ...missionChanges,
                });
              }
            }

            dispatch(removeMissionPendingRequest({
              missionId: mission.id,
              requestType: request.requestType,
            }));
          }
        });
      });
    }, 1000);
    
    return () => {
      dockConnectionManager.unsubscribeGroupMessages(dockMessageHandlerIds.current);
      frontendConnectionManager.unsubscribeGroupMessages(frontGroupMessageHandlerIds.current);
      clearInterval(pendingRequestsIntervalId.current);
    }
  }, []);

  return {};
}
