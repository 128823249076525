import { useDispatch, useSelector } from 'react-redux';
import './OperationDashboard.sass';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SidePanel from 'src/hci/organism/SidePanel/SidePanel';
import Loading from 'src/ui/Loading/Loading';
import { MdOutlineClose } from 'react-icons/md';
import MissionFlightDashboard from './common/MissionFlightDashboard/MissionFlightDashboard';
import { activeMissionTrackingId, setSelectedEntity } from '../ExploreAppSlice';
import MapViewer from './common/MapViewer/MapViewer';
import { dockConnectionManager, mobileConnectionManager, onboardConnectionManager } from 'src/helper/HubConnectionManager';
import DeviceMap from 'src/components/DeviceMap/DeviceMap';
import WaypointCommandPanel from './common/WaypointCommandPanel/WaypoointCommandPanel';
import { setRunMissionRequest } from 'src/services/mission/MissionServiceSlice';
import { deviceTypes, djiWaylineOutOfControlActions, djiWaylineTaskTypes } from 'src/helper/constants';
import MissionLogViewer from 'src/services/mission/MissionLogViewer/MissionLogViewer';
import { generateId } from 'src/helper/utils';
import Button from 'src/hci/common/Button/Button';
import { EntityType } from '../ExploreApp';
import EntityDetails from './common/EntityDetails/EntityDetails';
import DockCamera from './common/DockCamera/DockCamera';
import { TbArrowBack, TbCross, TbHandMove, TbLocationBolt, TbRouteSquare, TbX } from 'react-icons/tb';
import DroneCamera from './common/DroneCamera/DroneCamera';
import MissionInfoPanel from './common/MissionInfoPanel/MissionInfoPanel';
import useMissionInfo from 'src/services/mission/common/useMissionInfo';
import useMissionController from 'src/services/mission/common/useMissionController';
import { MissionStatus, MissionType, NotActiveMissionStatusSet } from 'src/services/mission/common/missionConstants';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import { DeviceOnlineStatus } from 'src/helper/useOnboardDeviceList';
import { TbHomeUp } from 'react-icons/tb';
import useDeviceTelemetries from 'src/services/device/common/useDeviceTelemetries';
import useDialog from 'src/helper/useDialog';
import useDeviceConnection from 'src/services/device/common/useDeviceConnection';
import MobileCamera from './common/MobileCamera/MobileCamera';
import DebugPanel from './common/DebugPanel/DebugPanel';
import useSubDevice from 'src/services/device/common/useSubDevice';
import OperationTaskbar from './common/OperationTaskbar/OperationTaskbar';

export const StageElement = {
  MAP: 'map',
  DOCK: 'dock',
  DOCK_DRONE: 'dock_drone',
  DRONE: 'drone_controller',
  MOBILE_DEVICE: 'mobile_device'
};

function OperationDashboard() {
  const entity = useSelector(state => state.exploreApp.selectedEntity);
  const disableKeyboardEvents = useSelector(state => state.ui.disableKeyboardEvents);
  const dispatch = useDispatch();
  const dialog = useDialog();
  const confirmLandingDialogActive = useRef();
  const deviceInfo = useDeviceInfo(entity?.id);
  const telemetries = useDeviceTelemetries(entity?.id);
  const { telemetries: subDeviceTelemetries } = useSubDevice(entity?.id);
  const connection = useDeviceConnection(entity?.id);
  const entityOnlineStatus = deviceInfo?.onlineStatus;
  const [activeStageElement, setActiveStageElement] = useState(StageElement.MAP);
  const issueMissionResult = useSelector(state => state.missionService.issueMissionResult);
  const executeMissionResult = useSelector(state => state.missionService.executeMissionResult);
  const missionController = useMissionController(item => item.deviceId === entity?.id, entity?.id);
  const [showDfrForm, setshowDfrFormForm] = useState(false);
  const [showMissionObserver, setShowMissionObserver] = useState(false);

  const forceLandingPending = useMemo(() => {
    const currHeight = telemetries?.altitudeInfo?.asl - telemetries?.gps?.homepoint?.alt;

    if(currHeight <= 0) return false;

    return (
      telemetries?.flightControl?.displayMode == 12 &&
      (currHeight - 0.7 < 0.001)
    );
  }, [telemetries]);

  const dfrCapable = (deviceInfo.type === deviceTypes.DRONE || deviceInfo.type === deviceTypes.DOCK) && 
  entityOnlineStatus === DeviceOnlineStatus.ONLINE && 
  !missionController.details?.status;

  const manualCapable = deviceInfo?.type=== deviceTypes.DRONE && 
  entityOnlineStatus === DeviceOnlineStatus.ONLINE;

  const rthAvailable = telemetries?.flightControl?.flightStatus === 2 || 
  (entity?.type === EntityType.DOCK && ![undefined, 0, 14].includes(subDeviceTelemetries?.mode_code));

  const swapStageElement = (element) => {
    setActiveStageElement(element);
  }

  const handleDfrButtonClick = () => {
    setshowDfrFormForm(true);
  }

  const handleManualButtonClick = () => {
    swapStageElement(StageElement.DRONE);
  }

  const handleMissionPanelClose = () => {
    setShowMissionObserver(false);
  }

  const handleOnCloseClick = () => {
    dispatch(setSelectedEntity(null));
  }

  const handleReturnHomeButtonClick = () => {
    if(entity.entityType === EntityType.DOCK) {
      if(missionController.details?.status === MissionStatus.RETURNING)
        missionController.cancelRth();
      else {
        missionController.rth();
      }
    }
    else {
      telemetries?.flightControl?.displayMode == 15 ? missionController.cancelRth() : telemetries?.flightControl?.displayMode == 12 ? missionController.cancelLanding() : missionController.rth()
    }
  }

  const handleKeyboard = useCallback((event) => {
    var key = event.code.replace("Arrow", ""); // ArrowUp -> Up, etc... for Edge and IE 11
    if(!subDeviceTelemetries?.['80-0-0']) return;

    if(key === "KeyW" || key === "KeyS" || key === "KeyA" || key === "KeyD"){
      const ratio = 10;
      const pitchMultiply = key === "KeyW" ? 1 : key === "KeyS" ? -1 : 0;
      const yawMultiply = key === "KeyA" ? -1 : key === "KeyD" ? 1 : 0;

      missionController.liveFlight?.sendCameraScreenDrag({
        locked: !!event.shiftKey,
        payload_index: "80-0-0",
        pitch_speed: ratio * pitchMultiply,
        yaw_speed: ratio * yawMultiply,
      });
    }

    if(key === "KeyR"){
      missionController.liveFlight?.sendGimbalReset({
        payload_index: "80-0-0",
        reset_mode: 0
      });

      event.preventDefault();
    }
  }, [subDeviceTelemetries]);

  useEffect(() => {
    if(forceLandingPending && !confirmLandingDialogActive.current) {
      dialog.fire({
        title: <b>Auto Landing</b>,
        text: "Do you want to finalize landing?",
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Confirm Landing',
      }).then(result => {
        if (result.isConfirmed) {
          onboardConnectionManager?.sendToGroup(entity?.id, connection?.group?.send?.commandChannel, {
            flightControl: {
              actionId: 6,
            }
          });
        }

        setTimeout(() => confirmLandingDialogActive.current = false, 2000);
      });

      confirmLandingDialogActive.current = true;
    } else if(confirmLandingDialogActive.current) {
      dialog.close();
      confirmLandingDialogActive.current = false;
    }
  }, [forceLandingPending]);

  useEffect(() => {
    if(missionController?.details?.status) {
      setShowMissionObserver(true);
    }
  }, [missionController])

  useEffect(() => {
    if(issueMissionResult) {
      console.log('📦 MISSION ISSUE RESULT', issueMissionResult);
    }
  }, [issueMissionResult]);

  useEffect(() => {
    if(executeMissionResult) {
      console.log('📦 MISSION EXECUTE RESULT', executeMissionResult)
    }
  }, [executeMissionResult]);

  useEffect(() => {
    if(disableKeyboardEvents) {
      document.removeEventListener('keydown', handleKeyboard);
    }
    else {
      document.addEventListener('keydown', handleKeyboard);
    }
  }, [disableKeyboardEvents]);

  useEffect(() => {
    setActiveStageElement({
      [EntityType.DOCK]: StageElement.DOCK,
      [EntityType.DRONE]: StageElement.DRONE,
      [EntityType.MOBILE_DEVICE]: StageElement.MOBILE_DEVICE,
    }[entity?.type] || null);
    setshowDfrFormForm(false);
  }, [entity]);

  useEffect(() => {
    if(deviceInfo?.type !== deviceTypes.DRONE && deviceInfo?.type !== deviceTypes.DOCK) 
      return;

    if(deviceInfo.onlineStatus !== DeviceOnlineStatus.ONLINE)
      handleOnCloseClick();
  }, [deviceInfo])

  useEffect(function setKeyboardEventListeners() {
    document.removeEventListener('keydown', handleKeyboard);
    document.addEventListener('keydown', handleKeyboard);

    return () => {
      document.removeEventListener('keydown', handleKeyboard);
    }
  }, [handleKeyboard]);

  return (
    <div className="operation-dashboard">
      {!!entity && (
        <SidePanel className={'entity-panel'} smallCollapseButton emptyHeader>
          <div className="side-panels-container">
            <EntityDetails entity={entity} onClose={handleOnCloseClick} />
            {(entity?.entityType === EntityType.DRONE || entity?.entityType === EntityType.DOCK) && (
              <div className="entity-actions">
                {deviceInfo.type !== deviceTypes.DRONE && (
                  <>
                    <Button 
                      onClick={handleDfrButtonClick} 
                      color="red"
                      size="1"
                      disabled={!dfrCapable} 
                      style={{flexShrink: 1}}
                    >
                      <TbLocationBolt className="icon" /> DFR
                    </Button>
                    {deviceInfo.type !== deviceTypes.DOCK && (
                      <Button onClick={handleManualButtonClick} disabled={!manualCapable} size="1"><TbHandMove  className="icon" /> CTRL</Button>
                    )}
                  </>
                )}
                {/* <Button disabled><TbRouteSquare className="icon" /> Missions</Button> */}
                <Button onClick={handleReturnHomeButtonClick} disabled={!rthAvailable} size="1" style={{flexGrow: '2'}}>
                  { telemetries?.flightControl?.displayMode == 15 || missionController.details?.status === MissionStatus.RETURNING ? 
                    <><TbX className="icon" /> CANCEL RETURN</> :
                    telemetries?.flightControl?.displayMode == 12 ?
                    <><TbX className="icon" /> STOP LANDING</> :
                    <><TbArrowBack className="icon" /> RETURN HOME</>
                  }
                </Button>
              </div>
            )}
            {showMissionObserver && deviceInfo?.id && (missionController?.details?.deviceId || missionController?.details?.dockId) === deviceInfo?.id && (
              <MissionInfoPanel deviceId={entity?.id} onClose={handleMissionPanelClose} controller={missionController} />
            )}
            {(showDfrForm || deviceInfo.type === deviceTypes.DRONE || missionController?.details?.missionType === MissionType.DFR) && (!missionController?.details?.status || NotActiveMissionStatusSet.includes(missionController.details?.status)) && (
              <WaypointCommandPanel deviceId={entity?.id} onCancel={() => setshowDfrFormForm(false)} controller={missionController} />
            )}
            { entity?.entityType === EntityType.DOCK && (
              <DebugPanel entity={entity} collapsed={true} />
            )}
          </div>
        </SidePanel>
      )}
      <div className="main-layout">
        <div className="stage-container">
          {
            {
              [StageElement.DOCK]: <DockCamera key={"dock-stage-" + entity?.id} deviceId={entity?.id} expanded={true} pip={true} />,
              [StageElement.DOCK_DRONE]: <DockCamera key={"dock-drone-stage-"  + entity?.id} deviceId={entity?.id} expanded={true} subDevice={true} />,
              [StageElement.MOBILE_DEVICE]: <MobileCamera key={"mobile-stage-"  + entity?.id} deviceId={entity?.id} expanded={true} />,
              [StageElement.DRONE]: <MissionFlightDashboard key={"drone-stage-" + entity?.id} deviceId={entity?.id} />,
            }[activeStageElement]
          }
          <DeviceMap key="map-stage" />
        </div>
        <OperationTaskbar />
      </div>
    </div>
  )
}

export default OperationDashboard