import InputBox from "../../../../../ui/InputBox/InputBox";
import "./EditDock.sass";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import useDebounce from "../../../../../helper/use-debounce";
import { checkName, name, updateDock } from "../DockManagementSlice";
import { useSelector } from "react-redux";
import Select from "../../../../../ui/Select/Select";
import { dataStreamingRegions, measurementUnitItems } from "../../../../../helper/constants";
import Button from "../../../../../ui/Button/Button";
import { useSettings } from "src/helper/SettingsProvider";
import { useMemo } from "react";

function EditDock({ onConfirm, onOpen }) {
  const [required] = useState(["dataStreamingRegion"]);
  const [alerts, setAlerts] = useState({});
  const [data, setData] = useState({});
  const [DockName, setDockName] = useState("");
  const [oldDockName, setOldDockName] = useState("");
  const [icons, setIcons] = useState({ DockName: "default" });
  const DebouncedBusinessName = useDebounce(DockName, 1000);
  const [validate, setValidate] = useState(true);
  const dock = useSelector((state) => state.dock);
  const table = useSelector((state) => state.table);
  const settings = useSettings();
  const dispatch = useDispatch();

  // const videoStreamingRegions = useMemo(() => {
  //   return settings.videoStreamRegions ? settings.videoStreamRegions.map(item => ({
  //     id: item.id,
  //     name: item.name,
  //     value: item.name
  //   })) : [];
  // }, [settings]);

  useEffect(() => {
    setData({
      dockName: table.row.dockName,
      //videoStreamingRegion: table.row.videoStreamingRegion,
      dataStreamingRegion: table.row.dataStreamingRegion,
      // unitOfMeasurement: table.row.unitOfMeasurement,
    });
    setOldDockName(table.row.dockName);
    setAlerts({});
  }, [table.row]);

  useEffect(() => {
    console.log(data.dockName, DockName);
    if (DebouncedBusinessName && oldDockName !== DockName) {
      dispatch(name({ status: "" }));
      //dispatch(checkName(DockName));
    }
  }, [DebouncedBusinessName]);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setAlerts({});

    if (key === "dockName") {
      setIcons({ ...icons, DockName: "default" });
      setDockName(value);
      setValidate(true);
    }
    if (key === "videoStreamingRegion" || key === "dataStreamingRegion" || key === "unitOfMeasurement") {
      setValidate(true);
    }

    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };
  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const submitHandler = () => {
    if (table.row !== undefined) dispatch(updateDock(data, table.row.id));
  };

  useEffect(() => {
    if (dock.name.status === "failed") {
      setAlerts({
        ...alerts,
        DockName: {
          alert: true,
          type: "error",
          message: dock.name.message,
        },
      });
    } else if (dock.name.status === "forbidden") {
      setAlerts({
        ...alerts,
        DockName: {
          alert: true,
          type: "error",
          message: dock.name.message.error,
        },
      });
    } else if (dock.name.status === "validation") {
      let validations = {};
      dock.name.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    }
    setIcons({ ...icons, DockName: dock.name.status });
    setValidate(
      // dock.name.status === "success" &&
      //data?.videoStreamingRegion !== undefined &&
      data?.dataStreamingRegion !== undefined
      // data?.unitOfMeasurement !== undefined
    );
  }, [dock.name]);

  useEffect(() => {
    if (dock.update.status === "validation") {
      let validations = {};
      dock.update.errors.forEach((item) => {
        validations[item.propertyName.replace(/\s+/g, "-").toLowerCase()] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    } else if (dock.update.status === "success") {
      onConfirm();
    }
  }, [dock.update]);

  useEffect(() => {
    onOpen?.();
  }, [data]);

  return (
    <div className="edit-dock">
      <InputBox
        title="Dock Name"
        name="dockName"
        onChange={changeHandler}
        onFocus={focusHandler}
        status={alerts.DockName}
        value={data.dockName}
        index={0}
        background="white"
        width="100%"
        icon={icons.DockName}
      />
      {/* <Select
        isRequired={required.includes("videoStreamingRegion")}
        title="Video Stream Region"
        name="videoStreamingRegion"
        items={videoStreamingRegions}
        onChange={changeHandler}
        onFocus={focusHandler}
        status={alerts.videoStreamingRegion}
        value={data.videoStreamingRegion}
        current={data.videoStreamingRegion}
        index={1}
        background="white"
        width="100%"
      /> */}
      <Select
        isRequired={required.includes("dataStreamingRegion")}
        title="Data Stream Region"
        name="dataStreamingRegion"
        items={dataStreamingRegions}
        onChange={changeHandler}
        onFocus={focusHandler}
        status={alerts.dataStreamingRegion}
        value={data.dataStreamingRegion}
        current={data.dataStreamingRegion}
        index={1}
        background="white"
        width="100%"
      />
      {/* <Select
        isRequired={required.includes("unitOfMeasurement")}
        title="Measurement Unit"
        name="unitOfMeasurement"
        items={measurementUnitItems}
        onChange={changeHandler}
        onFocus={focusHandler}
        status={alerts.unitOfMeasurement}
        value={data.unitOfMeasurement}
        current={data.unitOfMeasurement}
        index={1}
        background="white"
        width="100%"
      /> */}
      <Button
        value="Update"
        color="#2699fb"
        width="30%"
        disabled={!validate}
        onClick={submitHandler}
      />
    </div>
  );
}

export default EditDock;
